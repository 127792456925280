.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 90px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 0;
  font-weight: bold;
  /* Position the tooltip */
  position: absolute;
  z-index: 99;
  top: 100%;
  left: 50%;
  margin-left: -45px;
  margin-top: 10px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
