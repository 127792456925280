
/* .container {
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  max-height: 100vh;

  align-items: center;
  justify-content: center;
  text-align: center;
} */
 
/* @media only screen and (min-width: 600px) {
  .localStreamContainer {
    max-width: 150px;
  }
} */

.selfVideo {
  position: fixed;
  height: 150px;
  min-width: 100px;
  max-width: 150px;
  right: 10px;
  top: 10px;
  border-radius: 8px;
}

.mainVideo {
  max-height: 100vh;
  max-width: 100vw;
  height: 100%;
  margin: 0 auto;
  z-index: 2;
}

.actionBarWrapper {
  position: fixed;
  display: flex;
  background: rgba(154, 160, 166, 0.5);
  width: 100%;
  bottom: 10px;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 10;
}

.actionBarContainer {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  flex: 1;
  display: flex;
  align-content: center;
  justify-content: space-around;
}

.positionCenter {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #000;
}

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #fff;
  font-size: large;
}