@tailwind base;

:root {
  --primary: white;
  --secondary: rgb(236 72 153);
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 14px;
  border: 3px solid var(--primary);
}

audio::-webkit-media-controls-mute-button,
video::-webkit-media-controls-mute-button {
  display: none !important;
}

audio::-webkit-media-controls-volume-slider,
video::-webkit-media-controls-volume-slider {
  display: none !important;
}

audio::-webkit-media-controls-volume-slider-container,
video::-webkit-media-controls-volume-slider-container {
  display: none !important;
}

audio::-webkit-media-controls-panel,
video::-webkit-media-controls-panel {
  -webkit-transform: translateY(0%) !important;
  transform: translateY(0%) !important;
}

@tailwind components;
@tailwind utilities;

html, body {
  padding: 0;
  margin: 0;
}