.btnCamera {
  cursor: pointer;
  border: 0 none;
  background: violet;
  color: white;
  padding: 15px;
  border-radius: 35px;
  margin-left: 10px;
}

.btnDown {
  cursor: pointer;
  border: 0 none;
  background: red;
  color: white;
  padding: 15px;
  border-radius: 35px;
  margin-left: 10px;
}

.btnDisabled {
  cursor: pointer;
  border: 0 none;
  background: #fff;
  color: #333;
  padding: 15px;
  border-radius: 35px;
  margin-left: 10px;
}
